import React, { useEffect, useState } from 'react';
import confetti from 'canvas-confetti';
import { AiFillEdit, AiFillDelete, AiOutlinePlus } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Carousel from 'react-bootstrap/Carousel';
import Dropzone from '../dropzone';
import video from '../../asses/img/Actualizar_datos_be_parking.mp4';
import anniversaryVideo from '../../asses/img/Aniversario be parking.mp4';
import reelAcumulative from '../../asses/img/Reel_cómo_ver_puntos.mp4';

const ModalDatos = ({ show, handleClose, modalType }) => {
  let intervalId = null;

  const throwConfetti = () => {
    intervalId = setInterval(() => {
      confetti({
        particleCount: 50,
        startVelocity: 30,
        spread: 360,
        ticks: 60,
        origin: { x: Math.random(), y: Math.random() - 0.2 },
      });
    }, 250);
  };

  const stopConfetti = () => {
    clearInterval(intervalId);
  };

  useEffect(() => {
    if (show) {
      throwConfetti();
    } else {
      stopConfetti();
    }

    return () => {
      stopConfetti();
    };
  }, [show]);
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        {/* <Modal.Title >¿Cómo Actualizar Datos Beparking?</Modal.Title> */}
        <Modal.Title>
          {modalType === 'anniversary'
            ? 'Aniversario Be Parking'
            : '¿Cómo ver cuántos puntos tengo?'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="video_datos">
        <video width="100%" height="400" controls>
          <source
            src={
              modalType === 'anniversary'
                ? anniversaryVideo
                : modalType === 'acumulativePoints'
                ? reelAcumulative
                : ''
            }
            type="video/mp4"
          />
          Tu navegador no soporta el elemento de video.
        </video>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDatos;
