import React, { useState, useContext, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as ROUTES from '../../constans/rutas';

import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterBeparking from '../../components/footer/beparking';
import EditBeparking from '../../components/modal/modalBeparking';
import AcumularComponent from '../../components/acumular';
import LoginComponent from '../../components/login';
import ModalDatos from '../../components/modal/modalDatos';

import BannerBe from '../../asses/img/Banner_Be_Parking.png';
import estrella from '../../asses/img/icon-estrella.png';
import estrella2x from '../../asses/img/icon-estrella@2x.png';
import estrella3x from '../../asses/img/icon-estrella@3x.png';
import estrellah from '../../asses/img/icon-estrella-hover.png';
import estrellah2x from '../../asses/img/icon-estrella-hover@2x.png';
import estrellah3x from '../../asses/img/icon-estrella-hover@3x.png';
import parkito from '../../asses/img/parkito.png';
import parkito2x from '../../asses/img/parkito@2x.png';
import parkito3x from '../../asses/img/parkito@3x.png';
import imgNoticia from '../../asses/img/1-Hora-Gratis-V.png';

const BePrkingPage = () => {
  const authContex = useContext(AuthContex);
  const { usuario, loginBeparking } = authContex;
  const [login, setlogin] = useState(false);
  const [identificacion, setIdentificacion] = useState(''); //800675511
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showp, setShowp] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [editBanner, setEditBanner] = useState(false);
  const [editServivios, setEditServicios] = useState(false);
  const [modalNoticia, setModalNoticia] = useState(false);
  const [editAction, setEditAction] = useState('');
  const [typeModal, setTypeModal] = useState('anniversary');
  const [dataBaner, setDataBaner] = useState({
    id: null,
    url: null,
    nombre: '',
    nombre_original: '',
  });
  const [servicios, setServicios] = useState([]);
  const [alianzas, setAlianzas] = useState([]);
  const [beneficios, setBeneficios] = useState([]);
  const [parqueaderos, setParqueaderos] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [updateBanner, setUpdateBanner] = useState(false);
  const [updateServicio, setUpdateservicios] = useState(false);
  const [updateAlianzas, setUpdateAlianzas] = useState(false);
  const [updateBeneficios, setUpdateBeneficios] = useState(false);
  const [acepto, setAcepto] = useState(false);
  const [error, setError] = useState(false);
  const [key, setKey] = useState('home');
  const [tipobe, setTipobe] = useState('');
  const [windowWidth, setWindowWidth] = useState(0);
  const [modalShow, setModalShow] = useState(true);

  const handleModalClose = () => {
    setModalShow(false);
  };
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  const consultarBanner = async () => {
    setLoading(true);
    try {
      const resultado = await db.collection('BannerBe').orderBy('orden').get();
      const items = [];
      resultado.docs.forEach((doc) => {
        const item = doc.data();
        items.push(item);
      });
      setDataBaner(items);
      setLoading(false);
    } catch (error) {
      toast.error('Algo salio mal cargando el banner...');
      console.log(error.message);
      setLoading(false);
    }
  };

  const consultarBeneficios = async () => {
    setLoading(true);
    try {
      const resultado = await db
        .collection('Beneficios')
        .orderBy('orden')
        .get();
      const items = [];
      resultado.docs.forEach((doc) => {
        const item = doc.data();
        items.push(item);
      });
      setBeneficios(items);
      setLoading(false);
    } catch (error) {
      toast.error('Algo salio mal cargando las noticias...');
      console.log(error.message);
      setLoading(false);
    }
  };

  const onSubmit = (e) => {
    console.log('entre');
    e.preventDefault();

    if (identificacion.trim() === '' || password.trim() === '') {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    if (password.length < 3) {
      toast.error('La contraseña debe tener minimo 3 caracteres');
      return;
    }

    loginBeparking({ identificacion, password });
  };

  useEffect(() => {
    analytic.logEvent('page_view', { page: 'Home Beparking' });
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
    consultarBanner();
  }, [updateBanner]);

  useEffect(() => {
    consultarBeneficios();
  }, [updateBeneficios]);

  return loading ? (
    <div className="cargando">
      <div class="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <ModalDatos
        show={modalShow}
        handleClose={handleModalClose}
        modalType={typeModal}
      ></ModalDatos>

      <div
        className={
          dataBaner.length > 1
            ? 'banner-principal'
            : 'banner-principal sinflecha'
        }
      >
        <Carousel>
          {dataBaner.length > 0 &&
            dataBaner.map((item, index) => {
              return (
                <Carousel.Item key={item._id}>
                  <a href={item.nombre} target="_blank">
                    {windowWidth <= 575 ? (
                      <img
                        className="d-block w-100"
                        src={item.imagenM}
                        alt={item.nombre_originalM}
                      />
                    ) : (
                      <img
                        className="d-block w-100"
                        src={item.imagen}
                        alt={item.nombre_original}
                      />
                    )}
                  </a>
                </Carousel.Item>
              );
            })}
        </Carousel>

        {usuario?.cms ? (
          <Button
            onClick={() => {
              setEditAction('banner');
              setEditServicios(true);
            }}
            variant="outline-success"
            className="mtop-20 btn_banner"
          >
            <AiFillEdit /> Editar
          </Button>
        ) : null}
      </div>

      <Container>
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={10} lg={8} xl={8}>
            <h3 className="texto-slogan">
              Parking cerca de tí, cuidando lo que valoras.
            </h3>
          </Col>
        </Row>
      </Container>

      <BannerComponent />

      <Container>
        <Row className="justify-content-center mtop-50">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
            <Link to={ROUTES.BEINICIARSESION}>
              <Button
                className="btn-gigante"
                // onClick={() => {
                //   usuario ? setShowp(true) : setlogin(true);
                //   analytic.logEvent('click_acumular', {
                //     page: 'Home Beparking',
                //   });
                // }}
              >
                <img
                  src={estrellah}
                  className="img-fluid hover mtop-30"
                  srcSet={`${estrellah2x} 2x, ${estrellah3x} 3x`}
                  alt="icono estrella"
                />
                <img
                  src={estrella}
                  className="img-fluid normal mtop-30"
                  srcSet={`${estrella2x} 2x, ${estrella3x} 3x`}
                  alt="icono estrella"
                />
                Acumula tus puntos <span>AQUÍ</span>
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>

      <div className="be-titulo-fondo-verde mtop-50" id="beneficios">
        <Container>
          <Row className="justify-content-center">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="d-flex flex-row justify-content-between align-items-center"
            >
              <h3 className="texto-titulo">Beneficios Green</h3>
              <img
                src={parkito}
                className="img-fluid normal mtop-30"
                srcSet={`${parkito2x} 2x, ${parkito3x} 3x`}
                alt="Parkito"
              />
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        {usuario?.cms ? (
          <Button
            onClick={() => {
              setEditAction('beneficios');
              setEditServicios(true);
              setTipobe('Green');
            }}
            variant="outline-success"
            className="mtop-20 btn_banner"
          >
            <AiFillEdit /> Editar
          </Button>
        ) : null}
        <Row className="mtop-50">
          {beneficios.map((item) => {
            if (item.tipo === 'Green') {
              return (
                <Col
                  key={item._id}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  className="mbottom-20"
                >
                  <div className="be-cajas-blancas">
                    <img src={item.imagen} alt={item.nombre} />
                    <Link
                      className="btn-mas"
                      to={{
                        pathname: `${ROUTES.BEDETALLES}/${item._id}`,
                        item,
                      }}
                    >
                      Conoce más aquí
                    </Link>
                  </div>
                </Col>
              );
            }
          })}
        </Row>
      </Container>

      <div className="be-titulo-fondo-verde azul mtop-50">
        <Container>
          {usuario?.cms ? (
            <Button
              onClick={() => {
                setEditAction('beneficios');
                setEditServicios(true);
                setTipobe('Blue');
              }}
              variant="outline-success"
              className="mtop-20 btn_banner"
            >
              <AiFillEdit /> Editar
            </Button>
          ) : null}
          <Row className="justify-content-center">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="d-flex flex-row justify-content-between align-items-center"
            >
              <h3 className="texto-titulo">Beneficios Blue</h3>
              <img
                src={parkito}
                className="img-fluid normal mtop-30"
                srcSet={`${parkito2x} 2x, ${parkito3x} 3x`}
                alt="Parkito"
              />
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row className="mtop-50 mbottom-50">
          {beneficios.map((item) => {
            if (item.tipo === 'Blue') {
              if (item._id !== 'A4lz3wHJB9haUh8gZOaE') {
                return (
                  <Col
                    key={item._id}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                    className="mbottom-20"
                  >
                    <div className="be-cajas-blancas azul">
                      <img src={item.imagen} alt={item.nombre} />
                      <Link
                        className="btn-mas"
                        to={{
                          pathname: `${ROUTES.BEDETALLES}/${item._id}`,
                          item,
                        }}
                      >
                        Conoce más aquí
                      </Link>
                    </div>
                  </Col>
                );
              }
            }
          })}
        </Row>
      </Container>

      <FooterBeparking />

      <LoginComponent open={login} onOpen={(estado) => setlogin(estado)} />

      <AcumularComponent
        openAcomular={showp}
        onOpenAcomular={(estado) => setShowp(estado)}
      />

      {/* componentes CMS */}

      {usuario && usuario.cms ? (
        <>
          <EditBeparking
            show={editServivios}
            title={
              editAction === 'beneficios'
                ? 'Editar beneficios green'
                : editAction === 'alianzas'
                ? 'Editar alianzas'
                : editAction === 'noticias'
                ? 'Editar noticias'
                : editAction === 'banner'
                ? 'Editar banner'
                : null
            }
            text={
              editAction === 'beneficios'
                ? 'Agrega, edita o elimina beneficios green.'
                : editAction === 'alianzas'
                ? 'Agrega, edita o elimina alianzas.'
                : editAction === 'noticias'
                ? 'Agrega, edita o elimina noticias.'
                : editAction === 'banner'
                ? 'Agrega, edita o elimina banner'
                : null
            }
            textPrimary="Finalizar"
            textSecundary="Cancelar"
            onSecondary={() => setEditServicios(false)}
            onPrimary={() =>
              editAction === 'servicios'
                ? setUpdateservicios(!updateServicio)
                : editAction === 'alianzas'
                ? setUpdateAlianzas(!updateAlianzas)
                : editAction === 'beneficios'
                ? setUpdateBeneficios(!updateBeneficios)
                : editAction === 'banner'
                ? setUpdateBanner(!updateBanner)
                : null
            }
            action={editAction}
            data={
              editAction === 'servicios'
                ? servicios
                : editAction === 'alianzas'
                ? alianzas
                : editAction === 'beneficios'
                ? beneficios
                : editAction === 'banner'
                ? dataBaner
                : []
            }
            firebase={{ db, stg }}
            tipoBe={tipobe}
          />
        </>
      ) : null}
    </>
  );
};

export default BePrkingPage;
