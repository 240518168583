import React, { useContext, useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { Link } from 'react-scroll';
import { toast } from 'react-toastify';
import axios from 'axios';
import { animateScroll as scroll } from 'react-scroll';

import LoginComponent from '../login';
import AuthContex from '../../context/autenticacion/authContex';
import * as ROUTES from '../../constans/rutas';

import Logo from '../../asses/img/logo.png';
import Logo2x from '../../asses/img/logo@2x.png';
import Logo3x from '../../asses/img/logo@3x.png';
import LogoB from '../../asses/img/LogoBeparking.png';
import LogoB2x from '../../asses/img/LogoBeparking@2x.png';
import LogoB3x from '../../asses/img/LogoBeparking@3x.png';

import facebook from '../../asses/img/facebook.png';
import instagram from '../../asses/img/instagram.png';

const HeaderComponent = () => {
  const authContex = useContext(AuthContex);
  const {
    usuario,
    usuarioAutenticado,
    cerrarSesion,
    mensaje,
    autenticado,
    loginBeparking,
  } = authContex;
  const [show, setShow] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [identificacion, setIdentificacion] = useState(''); //800675511
  const [password, setPassword] = useState('');
  const [numFactura, setNumFactura] = useState('');
  const [codTerminal, setCodTerminal] = useState('');
  const history = useHistory(); // Inicializa el objeto de historial
  const location = useLocation();
  const [selectedGuide, setSelectedGuide] = useState('Guia'); // Valor por defecto

  useEffect(() => {
    console.log(location.pathname);
    console.log(ROUTES.BECONTRASENAG);
    // Actualiza el valor seleccionado en función de la ruta actual
    if (location.pathname === ROUTES.BEAYUDA) {
      setSelectedGuide(ROUTES.BEAYUDA);
    } else if (location.pathname === ROUTES.BECONTRASENAG) {
      setSelectedGuide(ROUTES.BECONTRASENAG);
    } else {
      setSelectedGuide('Guia');
    }
  }, [location.pathname]);
  const handleGuideChange = (event) => {
    const selectedRoute = event.target.value;
    setSelectedGuide(selectedRoute);
    history.push(selectedRoute); // Cambia de ruta al valor seleccionado
  };
  useEffect(() => {
    usuarioAutenticado();
  }, []);

  useEffect(() => {
    setShow(false);
    // history.push(ROUTES.BEPARKING);
    setIdentificacion(usuario?.cedula);
  }, [usuario]);

  console.log('LOCATION', location.pathname.split('/')[1]);

  return location.pathname.split('/')[1] === 'beparking' ? (
    <>
      <Navbar className="parking-color" expand="lg">
        <Container> 
          <NavLink to={ROUTES.BEPARKING} className="navbar-brand">
            <img
              src={LogoB}
              srcSet={`${LogoB2x} 2x, ${LogoB3x} 3x`}
              alt="Logo Parking"
            />
          </NavLink>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {location.pathname.split('/').length > 2 ? (
                <>
                  <NavLink activeClassName="drpMenu" to={ROUTES.BEPARKING}>
                    Beneficios
                  </NavLink>
                </>
              ) : (
                <>
                  <Link
                    to="beneficios"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    Beneficios
                  </Link>
                </>
              )}
              <div className="basic-navbar-nav">
                <select
                  value={selectedGuide}
                  className="nav-link-custom"
                  onChange={handleGuideChange}
                >
                  <option value="Guia">Guias</option>
                  <option value={ROUTES.BEAYUDA}>
                    Guía Actualización de Datos
                  </option>
                  <option value={ROUTES.AYUDAPUNTOS}>
                    Guía Acumulación de Puntos
                  </option>
                  {/* Agrega otras opciones de guías aquí */}
                </select>
              </div>

              <NavLink activeClassName="nav-borde nombre" to={ROUTES.CONTACTO}>
                Contáctanos
              </NavLink>
            </Nav>
            <Nav>
              {usuario ? (
                <>
                  <NavLink
                    activeClassName="nav-borde w-auto nombre"
                    to={ROUTES.BEPERFIL}
                    className="nav-borde"
                  >
                    {usuario.nombre}
                  </NavLink>
                  <button
                    activeClassName="drpMenu"
                    className="nav-borde w-auto nombre"
                    onClick={() => cerrarSesion()}
                  >
                    Cerrar sesión
                  </button>
                </>
              ) : (
                <>
                  <NavLink
                    activeClassName="drpMenu"
                    className="nav-borde nombre"
                    to={ROUTES.BEINICIARSESION}
                    // onClick={() => setShow(true)}
                  >
                    Inicia sesión
                  </NavLink>
                  <a
                    href="https://www.beparking.com.co/Register"
                    target="_blank"
                    rel="noopener noreferrer"
                    activeClassName="drpMenu"
                    className="nav-borde w-auto nombre"
                  >
                    Regístrate a be Parking
                  </a>
                  <NavLink activeClassName="drpMenu" to={ROUTES.HOME}>
                    Parking Principal
                  </NavLink>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
          <div className="redes">
            <a
              target="_blank"
              href="https://www.facebook.com/Parking-110519723977682/"
              className="d-none d-sm-inline-block"
            >
              <img src={facebook} alt="facebok parking" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/parking_oficial/?hl=es-la"
              className="d-none d-sm-inline-block"
            >
              <img src={instagram} alt="instagram parking" />
            </a>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
        </Container>
      </Navbar>
      <LoginComponent open={show} onOpen={(estado) => setShow(estado)} />
    </>
  ) : location.pathname.split('/')[1] === 'retentionCertificates' ? (
    <>
      <Navbar className="parking-color" expand="lg">
        <Container>
          <NavLink to={ROUTES.HOME} className="navbar-brand">
            <img
              src={Logo}
              srcSet={`${Logo2x} 2x, ${Logo3x} 3x`}
              alt="Logo Parking"
            />
          </NavLink>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <NavLink activeClassName="nav-borde nombre" to={ROUTES.CONTACTO}>
                Contáctanos
              </NavLink>
            </Nav>
            <Nav>
              {usuario ? (
                <>
                  <NavLink
                    activeClassName="drpMenu"
                    className="nav-borde w-auto nombre"
                    onClick={() => cerrarSesion()}
                    to={ROUTES.HOME}
                  >
                    Cerrar sesión
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink activeClassName="drpMenu" to={ROUTES.LOGINRETENTION}>
                    Administrador
                  </NavLink>
                  <NavLink activeClassName="drpMenu" to={ROUTES.HOME}>
                    Parking Principal
                  </NavLink>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
          <div className="redes">
            <a
              target="_blank"
              href="https://www.facebook.com/Parking-110519723977682/"
              className="d-none d-sm-inline-block"
            >
              <img src={facebook} alt="facebok parking" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/parking_oficial/?hl=es-la"
              className="d-none d-sm-inline-block"
            >
              <img src={instagram} alt="instagram parking" />
            </a>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
        </Container>
      </Navbar>
    </>
  ) : (
    <Navbar className="parking-color" expand="lg">
      <Container>
        <NavLink to={ROUTES.HOME} className="navbar-brand">
          <img
            src={Logo}
            srcSet={`${Logo2x} 2x, ${Logo3x} 3x`}
            alt="Logo Parking"
          />
        </NavLink>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavLink activeClassName="drpMenu" to={ROUTES.NOSOTROS}>
              Nosotros
            </NavLink>
            {location.pathname === '/' ? (
              <Link
                to="parqueaderos"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Parqueaderos
              </Link>
            ) : null}
            {location.pathname !== '/nosotros' ? (
              <NavLink
                to={ROUTES.CONTACTO}
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Contáctanos
              </NavLink>
            ) : (
              <NavLink activeClassName="drpMenu" to={ROUTES.CONTACTO}>
                Contáctanos
              </NavLink>
            )}
            <NavLink
              activeClassName="drpMenu"
              to={ROUTES.NOTICIAS}
              className="nav-borde"
            >
              Noticias
            </NavLink>
            <NavLink
              activeClassName="drpMenu"
              to={ROUTES.EMPRESA}
              className="nav-borde"
            >
              Soy Empresa
            </NavLink>

            <div className="basic-navbar-nav">
              {/* <NavLink> */}
              {/* <select
                value={selectedGuide}
                className="factelec"
                onChange={handleGuideChange}
              >
                <option value="Guia">Quiero Mi Factura Electrónica</option>
                <option value={ROUTES.LANDINGFACTURACION}>Registrarse</option>
                <option value={ROUTES.LANDINGFACTURACIONVALIDACION}>
                  Generar Mi Factura Electrónica
                </option>
              </select> */}
            </div>

            <NavLink
              activeClassName="drpMenu"
              to={ROUTES.LANDINGFACTURACION}
              className="nav-borde"
            >
              Quiero mi factura electrónica
            </NavLink>
          </Nav>

          <Nav>
            {/* { usuario ? 
                            <Nav.Link as="button" onClick={ () => cerrarSesion() }>Cerrar sesion</Nav.Link>
                            :
                            null
                            } */}
            <NavLink to={ROUTES.LANDINGPORTAL} className="nav-borde">
              Servicios
            </NavLink>
            {usuario ? (
              usuario.cms === false ? (
                <NavLink
                  activeClassName="nav-borde nombre"
                  to={ROUTES.BEPARKING}
                  className="nav-borde"
                >
                  be parking
                </NavLink>
              ) : (
                <>
                  {/* <NavLink
                                            activeClassName="nav-borde nombre"
                                            to={ROUTES.BEUSUARIOS}
                                            className="nav-borde">
                                            Usuarios beParking
                                        </NavLink> */}
                  <NavLink
                    activeClassName="nav-borde nombre"
                    to={ROUTES.PERFIL}
                    className="nav-borde"
                  >
                    {usuario.nombre}
                  </NavLink>
                </>
              )
            ) : (
              <NavLink
                activeClassName="drpMenu"
                className="nav-borde nombre"
                to={ROUTES.BEPARKING}
              >
                be parking
              </NavLink>
            )}
          </Nav>
        </Navbar.Collapse>
        <div className="redes">
          <a
            target="_blank"
            href="https://www.facebook.com/Parking-110519723977682/"
            className="d-none d-sm-inline-block"
          >
            <img src={facebook} alt="facebok parking" />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/parking_oficial/?hl=es-la"
            className="d-none d-sm-inline-block"
          >
            <img src={instagram} alt="instagram parking" />
          </a>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
      </Container>
    </Navbar>
  );
};

export default HeaderComponent;
